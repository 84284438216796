module.exports = {
    siteUrl: `https://www.moresecure.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 6, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `More Secure Computing Brings Affordable Solutions for Your Business Transformation`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Empower More Secure customers to win more business, to run business more efficiently and securely with affordable open solutions.`, // This allows an alternative site description for meta data for pages.
    siteName: `More Secure Computing Inc.`,
    
    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `MoreSecure`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
    authors: [
        `john-li-ms`,
    ],
    tags: [`event`,`people`,`about`],
    googleTag: `GTM-MMZ9RRD`,
    mauticHost: `https://mt.moresecure.com`,
    focusItem: true,
    focusUrl: `//mt.moresecure.com/focus/1.js`,
    // Note: direct facebook chat integration has been moved into gatsby-browser.js so the chat will be consistent
    chatIntegration: false,
    // chatUrl: `//widget.manychat.com/107280000936609.js`,
    chatUrl: `https://app.respond.io/facebook/chat/plugin/38887/107280000936609`,
    facebookChatId: `107280000936609`,
}
