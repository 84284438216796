// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-transformation-js": () => import("./../../src/pages/DigitalTransformation.js" /* webpackChunkName: "component---src-pages-digital-transformation-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-js": () => import("./../../src/pages/Infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-privacy-js": () => import("./../../src/pages/Privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-js": () => import("./../../src/pages/Security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-store-js": () => import("./../../src/pages/Store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-templates-author-js": () => import("./../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-index-js": () => import("./../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-post-js": () => import("./../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

