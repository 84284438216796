import React, { Component, useRef, useEffect } from 'react'
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "gatsby";
import logo from "../images/logo.webp";
// import MessengerCustomerChat from 'react-messenger-customer-chat';
// import FacebookChat from "./common/FacebookChat";

import config from '../utils/siteConfig';

function EcwidScript(props) {
    const storeDiv =  useRef(null);
    const scriptRef = useRef(null);
    window.localStorage.setItem("show_ecwid_logs","true")
    window.ecwid_script_defer = true;
    window.ecwid_dynamic_widgets = true;
    window.ec = window.ec || Object();
    window.ec.storefront = window.ec.storefront || Object();
    window.ec.enable_catalog_on_one_page = true;
    window._xnext_initialization_scripts = [{
      widgetType: 'ProductBrowser',
      id: 'my-store-35535303',
      arg: ["id=productBrowser", "views=grid(20,3)"]  },
    //   {
    //     widgetType: 'CategoriesV2',
    //     id: 'my-categories-#####',
    //     arg: ["id=categoriesV2"]
    //   }
    //   ,
    //   {
    //     widgetType: 'SearchWidget',
    //     id: 'my-search-####',
    //     arg: ["id=searchWidget"]
    //   }
    ];
  
    var script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = 'https://app.ecwid.com/script.js?35535303&data_platform=code&data_date=2020-08-23';
    script.defer = true;
    script.ref=scriptRef;
    // console.log("into the store")
  
  
  useEffect(() => {
      if(!scriptRef.current){
          // console.log("---add script---")
        storeDiv.current.appendChild(script);
        // xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-27283900");
      }
  });
  
    return (
      <div ref={storeDiv}>
        <div className="ec-cart-widget"></div>
      </div>
  
      );
}

export default EcwidScript

// export default class EcwidStore extends Component {
//     componentDidMount() {
//         window.ecwid_script_defer = true;
//         window.ecwid_dynamic_widgets = true;
//         const script = document.createElement("script");
//         script.src = "https://app.ecwid.com/script.js?27283900&data_platform=code&data_date=2020-04-20";
//         script.async = true;
//         script.charSet = 'utf-8';
//         script.setAttribute('data-cfasync', 'false');
//         document.body.appendChild(script);
//         xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-27283900");
//     }
      
//     componentWillUnmount() {
//         // console.log("will unmount navbar")
//             let scriptToremove = "https://app.ecwid.com/script.js?27283900&data_platform=code&data_date=2020-04-20";
//             let allsuspects=document.getElementsByTagName("script");
//             // console.log("---allsuspects")
//             // console.log(allsuspects)
//             for (let i=allsuspects.length; i>=0; i--){
//                 if (allsuspects[i] && allsuspects[i].getAttribute("src")!==null 
//                     && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`)!== -1 ){
//                         // console.log("remove focusItem")
//                         // console.log(allsuspects[i])
//                         allsuspects[i].parentNode.removeChild(allsuspects[i])
//                 }
//             }
//     }

//     render() {
//         return (
//             <>
//                 <div id="my-store-27283900" />
//             </>
//         );
//     }
// }